.container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.match_container{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding: 0 0 0 0;
}

.heading_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding: 0 10px 0 10px;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.heading{
    font-size: 25px;
    color: #343A40;
}

.match_contests_container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.match_contest_sub_container{
    width: 100%;
    background-color: #F9FAFB;
    /* border: 2px solid #e5e7eb; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.contest_creator{
    color: #343A40;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    /* height: 0; */
}

.match_contest_header{
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.prize_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.prize_heading{
    color: #343A40;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    margin: 0;
    padding: 0;
}

.prize{
    color: #343A40;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 800;
    margin: 0;
    padding: 0;
}

.fees_btn{
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    background-color: rgb(233, 114, 23);
    color: white;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    border: none;
    outline: none;
    box-shadow: 0 10px 20px rgba(0,0,0,0.3);
}

.seats_left{
    width: 100%;
    margin-top: 15px;
}

.seats_left_text{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text{
    color: #343A40;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
}

.btn_container{
    width: 100%;
    display: grid;
    justify-content: center;
}

.join_btn{
    width: 150px;
}

.prizes_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.prizes_sub_container{
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.carousel_item{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #F9FAFB;
    border: 1px solid #e5e7eb;
    padding: 10px;
    height: fit-content;
}

@media screen and (max-width:1000px){
    .match_contests_container{
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
}

@media screen and (max-width:800px) {
    .match_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .heading{
        font-size: 18px;
        color: #343A40;
    }
}


@media screen and (max-width:650px){
    .match_contests_container{
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }
}