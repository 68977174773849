.calendar_container{
    display: grid;
    grid-template-columns: 1fr 1fr 100px;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 30px 0 10px 0;
}

.calendar{
    width: 100%;
}

.matches_container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    align-items:flex-start;
    align-content: stretch;
    padding: 30px 0 10px 0;
}

.heading_container{
    grid-column: span 3;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding: 0 10px 0 10px;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.heading{
    font-size: 25px;
    color: #343A40;
}

.message_box{
    grid-column: span 3;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -12px;
    border-radius: 0 0 3px 3px;
    padding: 0 0 10px 0;
}

.message{
    color: #343A40;
    font-size: 20px;
    text-align: center;
}

@media screen and (max-width:1150px){
    .matches_container{
        grid-template-columns: 1fr 1fr;
    }
    .heading_container{
        grid-column: span 2;
    }
    .message_box{
        grid-column: span 2;
    }
}

@media screen and (max-width:500px){
    .calendar_container{
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding: 30px 0 10px 0;
    }
    .search_btn{
        width: 150px;
    }
    
    .matches_container{
        grid-template-columns: 1fr;
    }
    .heading_container{
        grid-column: span 1;
    }
    .message_box{
        grid-column: span 1;
    }
}