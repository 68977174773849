.container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.matches_container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    align-items:flex-start;
    align-content: stretch;
    padding: 0 0 10px 0;
}

.heading_container{
    grid-column: span 3;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding: 0 10px 0 10px;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.heading{
    font-size: 20px;
    color: #343A40;
}

.message_box{
    grid-column: span 3;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -12px;
    border-radius: 0 0 3px 3px;
    padding: 0 0 10px 0;
}

.message{
    color: #343A40;
    font-size: 20px;
    text-align: center;
}

.btn{
    padding: 8px 10px 8px 10px;
    outline: none;
    border: none;
    box-shadow: 0 14px 50px rgba(0,0,0,0.3);
    /* background-color: #FF2929; */
    background-color: #2978ff;
    color:white;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
}
.btn:hover{
    background-color: #0858e2;
}
.btn:active{
    transform: scale(1.1);
}


@media screen and (max-width:1150px){
    .matches_container{
        grid-template-columns: 1fr 1fr;
    }
    .heading_container{
        grid-column: span 2;
    }
    .message_box{
        grid-column: span 2;
    }
}

@media screen and (max-width:500px){
    .matches_container{
        grid-template-columns: 1fr;
    }
    .heading_container{
        grid-column: span 1;
    }
    .message_box{
        grid-column: span 1;
    }
}