.container{
    margin: 0;
    display: grid;
    grid-template-columns: 180px auto;
    /* grid-template-columns: 0px auto; */
    width: 100%;
    background-color: #E5E5E5;
    position: relative;
}

.sidebar{
    background-color: #fff;
    width: 180px;
    /* width: 0px; */
    position: sticky;
    top: 0;
    left: 0;
    min-height: 100dvh;
    max-height: 100dvh;
    padding: 10px 10px 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar::-webkit-scrollbar {
    width: 3px; 
}
.sidebar::-webkit-scrollbar-thumb {
    background-color: #E5E5E5;
    border-radius: 4px;
}

.sidebar_sub_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
}

.logo_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 0;
    cursor: pointer;
}
.logo_image_container{
    width: 60px;
    height: 40px;
}

.logo{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logo_text{
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat',sans-serif;
    background: linear-gradient(270deg, #41324F 0%, #65A0A5 24.13%, #41324F 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.menu_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    margin-top: 80px;
}

.menu_btn{
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    color: #343A40;
    font-weight: 400;
    padding: 10px;
    min-width: 200px;
    border-radius: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.notification{
    width: 30px;
    height: 25px;
    object-fit: contain;
    position: relative;
}

.notification_circle{
    position: absolute;
    top: -10px;
    right: -5px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: #2978FE;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification_p{
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #fff;
    font-family: 'Montserrat',sans-serif;
}

.menu_asset{
    width: 30px;
    height: 25px;
    object-fit: contain;
}

.menu_btn.active{
    color: white;
    background-color: rgba(255, 0, 0, 0.839);
}

.logout_btn{
    margin-left: 20px;
}

.main{
    width: 100%;
    padding: 0 15px 20px 15px;
    min-height: 100dvh;
    /* overflow-x: hidden; */
}

.header{
    width: 100%;
    padding: 0 10px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 70px;
    min-height: 70px;
    background-color: rgba(255, 255, 255, 0.90);
    border-radius: 0 0 10px 10px;
    backdrop-filter: blur(50px);
    position: sticky;
    top: 0; 
    box-shadow: 0 5px 30px rgba(0,0,0,0.2);
    /* min-height: 70px; */
    z-index: 900;
}
.header_items{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.menu_icon{
    display: none;
}

label{
    display:flex;
     flex-direction:column;
     width:50px;
     cursor:pointer;
   }
   
   label .menu_span{
     background: #000;
     border-radius:10px;
     height:4px;
     margin: 4px 0;
     transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
   }
   
   
   .menu_span:nth-of-type(1){
     width:50%;
     
   }
   
   .menu_span:nth-of-type(2){
     width:100%;
   }
   
   
   .menu_span:nth-of-type(3){
     width:75%;
    
   }
   
   
   input[type="checkbox"]{
     display:none;
   }
   
   
   input[type="checkbox"]:checked ~ .menu_span:nth-of-type(1){
     transform-origin:bottom;
     transform:rotatez(45deg) translate(4px,0px)
   }
   
   
   input[type="checkbox"]:checked ~ .menu_span:nth-of-type(2){
     transform-origin:top;
     transform:rotatez(-45deg)
   }
   
   
   input[type="checkbox"]:checked ~ .menu_span:nth-of-type(3){
     transform-origin:bottom;
     width:50%;
     transform: translate(20px,-4px) rotatez(45deg);
   
   }

@media screen and (max-width:1000px){
    .container{
        margin: 0;
        display: grid;
        grid-template-columns: 0px auto;
        width: 100%;
        background-color: #E5E5E5;
        position: relative;
    }
    
    .sidebar{
        display: none;
        visibility: hidden;
        background-color: #fff;
        width: 0;
        position: sticky;
        top: 0;
        min-height: 100dvh;
        max-height: 100dvh;
        padding: 10px 20px 20px 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
        overflow-y: auto;
    }

    .header_items{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    .menu_icon{
        display: block;
        padding: 10px 0 10px 0;
    }

    .main{
        padding: 0 5px 10px 5px;
    }
    
}