.drawer{
    display: none;
}

@media screen and (max-width:1000px) {
    .drawer{
        display: block;
        width: 100%;
        max-width: 200px;
        min-height: 100dvh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 1000;
        transform: translateX(-3000px);
        transition: all 0.3s;
    }
    .drawer.show{
        min-height: 100dvh;
        transform: translateX(0);
        width: 100%;
        max-width: 200px;
        box-shadow: 0 15px 15px rgba(0,0,0,0.2);
        overflow: hidden;
        transition: all 0.3s;
    }

    .drawer_sub_container{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 30px;
        padding: 40px 10px 40px 10px;
    }
    
    .logo_container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0px;
    }
    .logo_image_container{
        width: 100px;
        height: 70px;
    }
    
    .logo{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    
    .logo_text{
        text-align: center;
        width: 100%;
        font-size: 20px;
        font-family: 'Montserrat',sans-serif;
        /* color: #343A40; */
        background: linear-gradient(270deg, #41324F 0%, #65A0A5 24.13%, #41324F 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .menu_container{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        margin-top: 0px;
    }
    
    .menu_btn{
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-family: 'Montserrat',sans-serif;
        color: #343A40;
        font-weight: 400;
        padding: 10px;
        border-radius: 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
    
    .menu_asset{
        width: 30px;
        height: 25px;
        object-fit: contain;
    }
    
    .menu_btn.active{
        /* color: rgb(233, 68, 68); */
        color: white;
        background-color: rgba(255, 0, 0, 0.839);
        min-width: 150px;
        max-width: 150px;
    }
    .logout_btn{
        margin-left: 20px;
    }
    .notification{
        width: 30px;
        height: 25px;
        object-fit: contain;
        position: relative;
    }
    
    .notification_circle{
        position: absolute;
        top: -10px;
        right: -5px;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        background-color: #2978FE;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .notification_p{
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #fff;
        font-family: 'Montserrat',sans-serif;
    }
}